:root { //chart color
  --primary-color: #041133;
  --secondary-color: #fe821e;
  --background-color: #F6F7FC;
  --dark-font-color: #32325D;
  --grey-font-color: #8898aa;
  --light-font-color: #ffffff;
  --dark-gray: #8898AA;
  --light-gray: #E9ECEF;
  --success-color: #2DCE89;
  --error-color: #ff4656;
  --disabled-color: rgb(170, 170, 170);
}

@font-face {
  font-family: RegularFont;
  src: url("../fonts/quicksand/Quicksand-Regular.ttf");
}
@font-face {
  font-family: SemiBoldFont;
  src: url("../fonts/quicksand/Quicksand-SemiBold.ttf ");
}
@font-face {
  font-family: BoldFont;
  src: url("../fonts/quicksand/Quicksand-Bold.ttf ");
}

h1, h2, h3, h4, h5, h6 {
  font-family: BoldFont, serif!important;
  font-weight: normal!important;
  color: var(--dark-font-color);
}
h2.light {
  color: var(--light-font-color)!important;
}

b {
  font-family: SemiBoldFont, serif!important;
  font-weight: normal!important;
}

input {
  font-family: RegularFont, serif!important;
}

body {
  padding-right: 0!important; //prevent adding padding to body on modal opening
}

.eless-font-bold {
  font-family: SemiBoldFont, serif;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}